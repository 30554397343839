* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex: 1 0 auto;
  width: 92%;
  margin: 0 auto;
  margin-top: 5em;

  @media only screen and (min-width: 1024px) {
    width: 1024px;
  }
}

a {
  color: #00A388;
  text-decoration: none;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;

  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

::selection {
  background-color: #00A388;
  color: white;
}

::-moz-selection {
  background-color: #00A388;
  color: white;
}
