.profile {
  text-align: center;

  .portrait {
    position: relative;
    max-width: 10rem;
    margin: 0.6em auto;

    .spinner {
      width: 10rem;
      height: 10rem;
      border: 0.5em solid #3d3d3d;
      border-right-color: #00A388;
      border-bottom-color: #00A388;
      border-radius: 50%;
      -webkit-transition: transform 0.8s ease-in-out;
      -moz-transition: transform 0.8s ease-in-out;
      transition: transform 0.8s ease-in-out;
    }
    &:hover .spinner {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .img {
      position: absolute;
      width: 100%;
      top: 0.5em;
      bottom: 0.5em;
      left: 0.5em;
      right: 0;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
  }
  .name {
    font-family: Roboto, sans-serif;
    font-weight: 100;
    font-size: 2rem;
    margin: 0.2em auto;

    b {
      font-weight: bold;
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    color: #00A388;
    margin: 0.8em auto;
  }
  .bio {
    font-size: 0.9rem;

    p {
      margin-top: 0.9em;
    }
    a:hover {
      color: initial;
    }
  }

  @media only screen and (min-width: 720px) {
    .portrait {
      max-width: 11rem;

      .spinner {
        width: 11rem;
        height: 11rem;
      }
    }
    .name {
      font-size: 2.4rem;
    }
    .title {
      font-size: 1.1rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    .portrait {
      max-width: 12rem;

      .spinner {
        width: 12rem;
        height: 12rem;
      }
    }
    .name {
      font-size: 2.5rem;
    }
    .title {
      font-size: 1.2rem;
    }
  }
}
